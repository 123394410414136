import { navigate } from "gatsby";
import sendEmail from './api_requests';

import LS from './LocalStorage';

import React from 'react';
import {Form, Icon, Input, Button} from 'antd';
const FormItem = Form.Item;

class NormalForm extends React.Component {
    constructor(props) {
        super(props);
        this.content = this.props.content;
        this.state={request_status:''};
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.request_status === "sending"){
            return;
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.products = this.props.products;
                values.email_type = 'order';
                this.setState({request_status: 'sending'});
                sendEmail(values).then(this.handleSuccessEvent.bind(this)).catch(this.handleErrorEvent.bind(this));
            }
        });
    };

    handleSuccessEvent=(response)=>{
        if(response.status === 'ok'){
            LS.clearStorage();
            navigate('/success');
        }else{
            console.log('error: ', response);
            this.setState({request_status: ''});
            navigate('/error', { state: { redirect_from_page: "checkout"}});
        }
    };

    handleErrorEvent=(response)=>{
        console.log('error: ', response);
        navigate('/error', { state: { redirect_from_page: "checkout"}});
    };

    render() {

        const {getFieldDecorator} = this.props.form;

        return (
                <div className="checkout-card customer-information">
                    <Form onSubmit={this.handleSubmit} className="simple-form">
                         <h2>{this.content.header}</h2>

                        <FormItem>
                            {getFieldDecorator('user_email', {
                                rules: [{required: true, whitespace:true, message: this.content.user_email_error_message}],
                            })(
                                <label>
                                    <span className="label-text">{this.content.email_label}</span>
                                    <Input type="email" name="email" placeholder={this.content.email_placeholder} />
                                </label>
                            )}
                        </FormItem>

                        <div className="flexbox-space-between no-alignment">
                             <FormItem>
                                {getFieldDecorator('first_name', {
                                    rules: [{required: true, whitespace:true, min:3, message: this.content.first_name_error_message}],

                                })(
                                    <label>
                                        <span className="label-text">{this.content.first_name_label}</span>
                                        <Input type="text" name="first-name" placeholder={this.content.first_name_placeholder}/>
                                    </label>
                                )}
                            </FormItem>

                             <FormItem>
                                {getFieldDecorator('last_name', {
                                    rules: [{required: true, whitespace:true, min:3, message: this.content.last_name_error_message}],

                                })(
                                    <label>
                                        <span className="label-text">{this.content.last_name_label}</span>
                                        <Input type="text" name="last-name" placeholder={this.content.last_name_placeholder}/>
                                    </label>
                                )}
                            </FormItem>
                        </div>

                        
                        <FormItem>
                            <label>
                                <span className="label-text">{this.content.country_label}</span>
                                <Input disabled type="text" name="country" placeholder={this.content.country_placeholder}/>
                            </label>
                        </FormItem>

                        <div className="order-note">
                            {this.content.place_order_note}
                        </div>

                        <FormItem>
                            <Button type="primary" htmlType="submit" className="cta big full-width center-align">                                
                                <strong>{this.content.place_order_button_label}</strong>
                                <small>{this.content.place_order_no_payment}</small>
                                {this.state.request_status === "sending" && <Icon type="loading" style={{ fontSize: 30,float:'right', marginTop: -35 }} spin />}
                            </Button>
                        </FormItem>
                    </Form>
                </div>
        );
    }
}

const CheckoutForm = Form.create()(NormalForm);

export default CheckoutForm;