import React from 'react';
import {graphql} from 'gatsby'

import Layout from '../components/Layout';
import CheckoutForm from '../components/CheckoutForm';
import LS from '../components/LocalStorage';
import {divide,floor} from '../components/utils';

const CheckoutPageTemplate = ({data})=> {
    const content = data.markdownRemark.frontmatter.form;
    let products = LS.getItems('products') || [];
    let total_price = products.reduce((a, product) => a + product.product_price / product.number_of_owners, 0);
    let products_order = products.map((product)=>(product.product_link_name));
    if(!products.length){
        return null;
    }
    return (
        <Layout>
            <section className="main-section gray">
              <div className="container">
                <a href="/cart" className="back-link"><img src="/images/icon-arrow-left.svg" /> {content.back_to_cart}</a>
              </div>

              <div className="container flexbox-space-between align-top">

                <CheckoutForm content={content} products={products_order} />

                <div className="checkout-card cart-summary">
                  <h2>{content.order_summary_header}</h2>
                  {
                      products.map((product, index)=>(
                          <div className="order-summary-line" key={index}>
                            <div className="product-name"><strong>{product.product_name}</strong></div>
                            <div className="product-data">{product.number_of_owners} {product.type_of_group} | {product.currency} {divide(product.product_price,product.number_of_owners)} {content.per_person}</div>
                            <div className="product-price"><strong>{content.total} {product.currency} {product.product_price}</strong></div>
                          </div>
                      ))
                  }

                  <div className="grand-total">
                    <span>{content.grand_total}</span>
                    <strong>{products[0].currency} {floor(total_price)}</strong>
                  </div>
                </div>
              </div>
            </section>
        </Layout>
    );
};

export default CheckoutPageTemplate;

export const CheckoutQuery = graphql`
  query CheckoutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {         
        form{
            header 
            email_label
            email_placeholder
            first_name_label 
            first_name_placeholder
            last_name_label 
            last_name_placeholder
            country_label 
            country_placeholder             
            place_order_button_label
            place_order_no_payment
            place_order_note
            textarea_label
            textarea_placeholder
            order_summary_header
            per_person
            grand_total
            total
            back_to_cart
            user_email_error_message 
            first_name_error_message 
            last_name_error_message
            country_input_tooltip
            city_input_tooltip
        }  
      }
    }
  }
`